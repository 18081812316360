/* stylelint-disable */
@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

$header-height: grid_units(8);

.container {
  display: flex;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: $header-height;
  padding: 0 grid_units(3);
  transition: transition_all(250);
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.logo {
  height: grid_units(3);
}

.logoWhite {
  display: none;
}

.transparent {
  background-color: transparent;
}

.whiteContent {
  .hamburger {
    color: $white !important; // Override inline styles
  }

  .logoColor {
    display: none;
  }

  .logoWhite {
    display: block;
  }
}

.hamburgerButton {
  width: grid_units(10);
  height: 100%;
  padding: 0;
  transform: translateX(24px);
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.placeholder {
  height: $header-height;
}
