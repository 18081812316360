/* stylelint-disable  */
@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';

.footer {
  background-color: $blue-600;
  .container {
    padding: $spaces-6 $spaces-3;
    @include screenIn(tablet) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 64px;
    }
    @include screenIn(desktop) {
      flex-direction: row;
      justify-content: space-between;
      gap: 123px;
      padding: 100px $spaces-3;
    }
  }
  .footerLogoContainer {
    @include screenIn(tablet) {
      text-align: center;
    }
    @include screenIn(desktop) {
      text-align: left;
    }
    .footerLogo {
      width: auto !important;
      height: 25px !important;
      @include screenIn(tablet) {
        height: 40px !important;
      }
    }
    .footerRights {
      margin-top: $spaces-2;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      @include screenIn(tablet) {
        margin-top: $spaces-3;
      }
    }
  }
  .footerTitles {
    margin-bottom: $spaces-3;
    color: $white;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  .footerLinksWrapper {
    @include screenIn(tablet) {
      display: flex;
      gap: 60px;
    }
    @include screenIn(desktop) {
      display: flex;
      gap: 140px;
    }
    .footerLinksContainer {
      margin-top: $spaces-5;
      @include screenIn(tablet) {
        margin-top: 0;
      }
      @include screenIn(desktop) {
        width: 175px;
      }
      .footerLink {
        display: block;
        margin-bottom: $spaces-2;
        text-decoration: none;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          color: $white;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          &:hover {
            color: $grey-100;
            text-decoration: underline;
          }
          &:active {
            color: $grey-200;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
