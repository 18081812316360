@import 'src/styles/spaces.scss';
@import 'src/styles/utils.scss';

.root {
}

.fields {
  display: flex;
  flex-direction: column;
  gap: $spaces-2;
  @include isDevice(desktop) {
    gap: $spaces-4;
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: $spaces-2;
  margin-top: $spaces-4;
  margin-bottom: $spaces-5;
  @include isDevice(desktop) {
    gap: $spaces-3;
    margin-top: $spaces-4;
    margin-bottom: $spaces-5;
  }
}
