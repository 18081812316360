/* stylelint-disable no-descending-specificity */

@import 'src/styles/grid.scss';

.content {
  @include screenIn(desktop) {
    padding-top: 64px;
  }

  &--transparent {
    margin-top: unset;
  }

  &--no-header {
    padding-top: 0;
  }
}
