/* stylelint-disable no-descending-specificity */

@import 'src/styles/utils.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.toastMsg {
  display: flex;
  position: fixed;
  z-index: 9;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: $spaces-7;
  padding: $spaces-2 $spaces-3;
  padding-right: $spaces-7;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  @include screenIn(desktop) {
    padding: $spaces-2 $spaces-11;
  }
}

.success {
  background-color: #2ba783;
}

.error {
  background-color: $red-500;
}

.iconContainer {
  position: absolute;
  z-index: 11;
  top: 50%;
  right: $spaces-2;
  transform: translateY(-50%);
  border: 0;
  outline: none;
  background: transparent;
}

.icon {
  color: $white;
  cursor: pointer;
}
