/* stylelint-disable */
@import 'src/styles/utils.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

$header-height: grid_units(8);

.headerContainer {
  display: flex;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100vw;
  transition: transition_all(250);
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1240px;
  height: $header-height;
  padding: 0 grid_units(3);
}

.logoLink {
  &:focus {
    outline: 1px solid $blue-600;
  }
}

.logo {
  height: 24px !important;
  width: auto !important;
}

.logoWhite {
  display: none;
}

.transparent {
  background-color: transparent;

  .logoLink {
    outline-color: $white;
  }

  .logoColor {
    display: none;
  }

  .logoWhite {
    display: block;
  }

  .hamburger {
    color: $white !important; // Override inline styles
  }
}

.placeholder {
  height: $header-height;
}

.links {
  display: flex;
  height: 100%;
  padding-left: grid_units(5);
}

.loginButton {
  p {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}

.localeWrapper {
  position: relative;
  z-index: 100;

  &:hover > .localeOptionsContainer {
    display: block;
  }

  &:hover > .iconToggle {
    transform: rotateY(180deg);
  }

  .localeButton {
    p {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      text-transform: capitalize;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .localeOptionsContainer {
    display: none;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 80%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 100%;

    .localeOption {
      color: $blue-600;
      font-weight: 400;
      text-decoration: inherit;
      display: block;
      border-radius: 4px;
      padding: $spaces-2;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $grey-100;
      }
    }

    .localeOptionSelected {
      font-weight: 700;
    }
  }

  .sublistContainer {
    min-width: 200px;
    .localeOption {
      text-align: left;
      padding: $spaces-1 $spaces-2;
      line-height: 24px;
    }
  }
}
.icon {
  text-decoration: none !important;
}
