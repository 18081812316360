/* stylelint-disable no-descending-specificity */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: grid_units(5);
  height: grid_units(5);
  padding: 0;
  font-size: 14px;
  letter-spacing: 0;
}

.iconButtonLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  transition: background-color 200ms ease;
  border: none;
  border-radius: grid_units(0.5);
  color: $white;
  text-decoration: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.red {
  background-color: $red-500;

  &:hover {
    background-color: $red-400;
  }

  &:active {
    background-color: $red-600;
  }

  &:disabled {
    background-color: $red-200;
  }
}

.blue {
  background-color: $blue-600;

  &:hover {
    background-color: $blue-500;
  }

  &:active {
    background-color: $blue-700;
  }

  &:disabled {
    background-color: $blue-200;
  }
}

.grey {
  background-color: $grey-500;

  &:hover {
    background-color: $grey-400;
  }

  &:active {
    background-color: $grey-600;
  }

  &:disabled {
    background-color: $grey-200;
  }
}

.burgundy {
  background-color: $burgundy-500;

  &:hover {
    background-color: $burgundy-400;
  }

  &:active {
    background-color: $burgundy-600;
  }

  &:disabled {
    background-color: $burgundy-200;
  }
}

.green {
  background-color: $accent-green-500;

  &:hover {
    background-color: $accent-green-400;
  }

  &:active {
    background-color: $accent-green-600;
  }

  &:disabled {
    background-color: $accent-green-200;
  }
}

.yellow {
  background-color: $accent-yellow-500;

  &:hover {
    background-color: $accent-yellow-400;
  }

  &:active {
    background-color: $accent-yellow-600;
  }

  &:disabled {
    background-color: $accent-yellow-200;
  }
}

.lightBlue {
  background-color: $blue-200;

  &:hover {
    background-color: $blue-300;
  }

  &:active {
    background-color: $blue-400;
  }

  &:disabled {
    background-color: $blue-100;
  }
}
