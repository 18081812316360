/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';

.sellYourHouseContainer {
  background-color: $grey-100;
  padding-bottom: $spaces-6;
  @include isDevice(tablet) {
    padding-bottom: $spaces-10;
  }
  @include isDevice(desktop) {
    padding-bottom: $spaces-11;
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    padding: 0 $spaces-3;
    gap: $spaces-5;
    @include isDevice(tablet) {
      flex-direction: column;
      gap: $spaces-6;
      padding-top: 100px;
    }
    @include isDevice(desktop) {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 100px;
    }
    .info {
      margin-top: $spaces-6;
      @include isDevice(tablet) {
        margin-top: 0;
        margin-bottom: 0;
      }
      @include isDevice(desktop) {
        max-width: 471px;
        margin-top: 0;
        margin-bottom: 0;
      }
      .title {
        letter-spacing: normal;
        margin-bottom: $spaces-2;

        @include isDevice(tablet) {
          margin-bottom: $spaces-3;
        }
        @include isDevice(desktop) {
          margin-bottom: $spaces-3;
        }
      }

      .contactButton {
        width: 100%;
        max-width: 362px;
        padding-left: 0;
        padding-right: 0;
        min-width: 0px;
        font-weight: bold;
        margin-block: $spaces-5;
        text-align: center;
      }

      .contactLinksContainer {
        display: flex;
        flex-direction: column;
        gap: $spaces-2;
        margin-top: $spaces-3;
        margin-bottom: $spaces-3;
        @include isDevice(tablet) {
          margin-top: $spaces-5;
          margin-bottom: $spaces-5;
          gap: 20px;
        }
        @include isDevice(desktop) {
          margin-top: $spaces-5;
          margin-bottom: $spaces-6;
          gap: $spaces-3;
        }
        .contactLink {
          display: inline-flex;
          align-items: center;
          max-width: fit-content;
          text-decoration: none;
          gap: $spaces-2;
          .contactLinkText {
            display: inline-block;
            color: $blue-600;
          }
          &:hover {
            .contactLinkText {
              color: $blue-400;
              text-decoration: underline;
            }
          }
          &:active {
            .contactLinkText {
              color: $blue-700;
              text-decoration: underline;
            }
          }
        }
      }
      .contactSocialLinksContainer {
        display: flex;
        flex-direction: row;
        gap: $spaces-2;
      }
    }
    .formContainer {
      @include isDevice(desktop) {
        max-width: 471px;
      }
    }
  }
}
