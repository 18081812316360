/* stylelint-disable */
@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';

.container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  text-decoration: none;

  .text {
    color: $blue-600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      color: $blue-400;
      text-decoration: underline;
      i {
        text-decoration: none;
      }
    }
    &:active {
      color: $blue-700;
      text-decoration: underline;
    }
  }
}

.hasIcon {
  .text {
    text-decoration: none !important;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

.isImportant {
  .text {
    padding: 8px;
    border: 1px solid $red-500;
    border-radius: 4px;
    color: $red-500;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;

    &:hover {
      border: 1px solid $red-400;
      color: $red-400;
    }
  }
}
.transparent {
  .text {
    color: $white;
  }
}
