@import 'src/styles/spaces';

.phone {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.inputs {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: fit-content;
  gap: $spaces-2;
}

.input {
  border: 1px solid red;
}

.inputPrefix {
  border: 1px solid red;
}

.errorMessage {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}
