/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.container {
  position: fixed;
  z-index: 18;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: grid_units(8);
  overflow-y: scroll;
  background-color: $white;
}

.open {
  display: block;
}

.userDashboardLinks {
  padding: grid_units(2) grid_units(4);
}

.helloUser {
  padding: grid_units(2) 0;
  border-bottom: 1px solid $blue-100;
}

.userDashboardLink {
  padding-left: 0;
}

.generalLink {
  display: flex;
  align-items: center;
  height: grid_units(6);
}

.userStaticLinks {
  padding: grid_units(2) grid_units(4) grid_units(10) grid_units(4);
}

.localeWrapper {
  position: relative;
  z-index: 100;

  &:hover > .localeOptionsContainer {
    display: block;
  }
  &:hover > .iconToggle {
    transform: rotateY(180deg);
  }

  .localeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    text-decoration: none;
    text-transform: capitalize;
    gap: 6px;
    .icon {
      margin-right: grid_units(1);
    }
    .text {
      color: $blue-600;
    }
  }

  .localeOptionsContainer {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 80%;
    right: 0;
    left: 0;
    width: fit-content;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);

    .localeOption {
      display: block;
      padding: $spaces-2;
      border-radius: 4px;
      color: $blue-600;
      font-weight: 400;
      text-align: center;
      text-decoration: inherit;
      cursor: pointer;

      &:hover {
        background-color: $grey-100;
      }
    }

    .localeOptionSelected {
      font-weight: 700;
    }
  }
}
