@import 'src/styles/utils.scss';
@import 'src/styles/grid.scss';

.headerMobile {
  display: block;

  @include screenIn(desktop) {
    display: none;
  }
}

.headerDesktop {
  display: none;

  @include screenIn(desktop) {
    display: flex;
  }
}
