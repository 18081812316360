/* stylelint-disable no-descending-specificity */

@import 'src/styles/utils.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.loadingContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
