@import 'src/styles/grid.scss';
@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';

.legalPrivacy {
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
